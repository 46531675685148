// Copyright © 2024 Niphtio, Inc.
// All Rights Reserved.

import { forwardRef, Input, InputProps } from '@chakra-ui/react';
import { useCollectionsQuery } from '~/common/gql/collection.generated';
import { getInputPlaceholder } from './getInputPlaceholder';

interface Props extends InputProps {}

export const CollectionPickerInput = forwardRef<Props, 'div'>(
  ({ ...props }, ref) => {
    const query = useCollectionsQuery();
    const isZeroCollections = query.data?.collectionQuery.length === 0; // false if error or loading
    return (
      <Input
        ref={ref}
        variant="outline"
        placeholder={getInputPlaceholder(isZeroCollections)}
        {...props}
      />
    );
  },
);
