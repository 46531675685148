// Copyright © 2021 Niphtio, Inc.
// All Rights Reserved.

import { useTodoItemCreateMutation } from '~/common/gql/todoItem.generated';
import { ActionType, UsageContext } from '~/common/utilities/usage-event';
import { createMutationHelperHook } from './createMutationHelperHook';

export const useTodoItemCreateMutationHelper = createMutationHelperHook(
  useTodoItemCreateMutation,
  {
    errorMessage: 'There was an error creating this todo item: ',
    log: {
      beforeMutation: (variables) => ({
        actionType: ActionType.CREATE_TODO_ITEM,
        itemId: variables.input.itemId,
        context: UsageContext.TODO,
      }),
      afterMutation: (variables, data) => ({
        actionType: ActionType.TODO_ITEM_CREATED,
        itemId: variables.input.itemId,
        context: UsageContext.TODO,
        label: data?.todoItemCreate?.id,
      }),
    },
  },
);
