// Copyright © 2024 Niphtio, Inc.
// All Rights Reserved.

import { useDisclosure } from '@chakra-ui/react';
import { useCallback } from 'react';
import { Section } from '~/common/gql';
import { EnhancedCollection } from '~/containers/common/Collection/EnhancedCollection';
import { ModalCollectionPickerProps } from '~/containers/common/Collection/ModalCollectionPicker/ModalCollectionPickerProps';
import { useCollectionSearch } from '~/containers/common/Collection/useCollectionSearch';
import { useCollectionSearchCreateCollection } from '~/containers/common/Collection/useCollectionSearchCreateCollection';
import { filterSectionsWithCollections } from '~/containers/common/Section/filterSectionsWithCollections';
import { useRefUpdater } from '~/hooks/useRefUpdater';

export interface Props {
  sections: Section[];
  enhancedCollections: EnhancedCollection[];
  onTagClick: (collection: EnhancedCollection) => void;
}

export const useModalCollectionPicker: (
  props: Props,
) => ModalCollectionPickerProps = ({
  sections,
  enhancedCollections,
  onTagClick,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { searchTerm, setSearchTerm, result } = useCollectionSearch({
    collections: enhancedCollections,
  });
  const resultSections = filterSectionsWithCollections({
    sections: sections,
    collections: result,
  });
  const createCollection = useCollectionSearchCreateCollection({
    setSearchTerm,
    toggleSelected: onTagClick,
    throttleInterval: 1000,
  });
  const createCollectionRef = useRefUpdater(createCollection);

  const createTag = useCallback(
    (title: string): any => {
      createCollectionRef.current({
        input: { title },
      });
    },
    [
      // dep array should be empty or only contain RefObjects
      createCollectionRef,
    ],
  );

  return {
    popup: {
      isOpen,
      onOpen: onOpen,
      onClose: onClose,
    },
    picker: {
      tags: result,
      sections: resultSections,
      searchTerm,
      onSearchTermChanged: setSearchTerm,
      onTagClick,
      createTag,
    },
  };
};
