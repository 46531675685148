// Copyright © 2022 Niphtio, Inc.
// All Rights Reserved.

import { forwardRef } from '@chakra-ui/react';
import {
  PanelHeading,
  PanelList,
  PanelListItem,
  PanelListProps,
  PanelPaddedBox,
} from '@niphtio/np-theme';
import isNil from 'lodash/isNil';
import { MdAdd, MdOutlineCheck } from 'react-icons/md';
import { getIsSharedCollection } from '~/common/utilities/collection-utils/getIsSharedCollection';
import { ReactIcon } from '~/components/Icons';
import { NpCollection } from '~/components/Icons/NpCollection';
import { NpSharedCollection } from '~/components/Icons/NpSharedCollection';
import { CollectionPickerProps } from '~/containers/common/Collection/CollectionPicker/CollectionPickerProps';

interface Props extends PanelListProps {
  tagPickerProps: Pick<
    CollectionPickerProps,
    'searchTerm' | 'tags' | 'onTagClick' | 'createTag' | 'sections'
  >;
}

export const CollectionPicker = forwardRef<Props, 'div'>(
  ({ tagPickerProps, ...props }, ref) => {
    const { searchTerm, tags, sections, onTagClick, createTag } =
      tagPickerProps;
    const existingTag = tags.find((tag) => tag.title == searchTerm);
    const orphanTags = tags.filter((tag) => isNil(tag.sectionId));

    return (
      <PanelList ref={ref} {...props}>
        {createTag && !existingTag && searchTerm?.length > 0 && (
          <PanelListItem
            leftIcon={<ReactIcon as={MdAdd} />}
            onClick={async () => createTag(searchTerm)}
          >
            Create new tag for &apos;{searchTerm}&apos;
          </PanelListItem>
        )}
        {orphanTags.map((it) => (
          <PanelListItem
            key={it.id}
            leftIcon={
              getIsSharedCollection(it) ? (
                <NpSharedCollection size="xs"></NpSharedCollection>
              ) : (
                <NpCollection size="xs"></NpCollection>
              )
            }
            onClick={() => onTagClick(it)}
            rightIcon={it.selected && <ReactIcon as={MdOutlineCheck} mx={2} />}
            aria-label={`${it.title}` + (it.selected ? ` - selected` : '')}
          >
            {it.title}
          </PanelListItem>
        ))}
        {sections.map((section) => (
          <>
            <PanelPaddedBox key={section.id}>
              <PanelHeading
                key={section.id}
                aria-label={section.title}
                fontSize="xs"
                color="npSubduedText"
              >
                {section.title}
              </PanelHeading>
            </PanelPaddedBox>
            {tags
              .filter((it) => it.sectionId === section.id)
              .map((it) => (
                <PanelListItem
                  key={it.id}
                  leftIcon={
                    getIsSharedCollection(it) ? (
                      <NpSharedCollection size="xs"></NpSharedCollection>
                    ) : (
                      <NpCollection size="xs"></NpCollection>
                    )
                  }
                  onClick={() => onTagClick(it)}
                  rightIcon={
                    it.selected && <ReactIcon as={MdOutlineCheck} mx={2} />
                  }
                  aria-label={
                    `${it.title}` + (it.selected ? ` - selected` : '')
                  }
                >
                  {it.title}
                </PanelListItem>
              ))}
          </>
        ))}
      </PanelList>
    );
  },
);
