// Copyright © 2021 Niphtio, Inc.
// All Rights Reserved.

import { useTodoItemUpdateMutation } from '~/common/gql/todoItem.generated';
import { ActionType, UsageContext } from '~/common/utilities/usage-event';
import { createMutationHelperHook } from './createMutationHelperHook';

export const useTodoItemUpdateMutationHelper = createMutationHelperHook(
  useTodoItemUpdateMutation,
  {
    errorMessage: 'There was an error updating this todo item: ',
    log: {
      beforeMutation: (variables) => ({
        actionType: ActionType.UPDATE_TODO_ITEM,
        context: UsageContext.TODO,
        label: variables.input.find.id.toString(),
        text: variables.input.markDone ? 'done' : 'not done',
      }),
      afterMutation: (variables, data) => ({
        actionType: ActionType.TODO_ITEM_UPDATED,
        itemId: data?.todoItemUpdate?.itemId,
        context: UsageContext.TODO,
        label: variables.input.find.id.toString(),
        text: variables.input.markDone ? 'done' : 'not done',
      }),
    },
  },
);
