// Copyright © 2025 Niphtio, Inc.
// All Rights Reserved.

import { Collection, Section } from '~/common/gql';

interface Props {
  sections: Section[];
  collections: Collection[];
}

export const filterSectionsWithCollections = ({
  sections,
  collections,
}: Props) => {
  const sectionIdsInCollections = new Set(
    collections.map((collection) => collection.sectionId),
  );

  const matchedSections = sections.filter((section) =>
    sectionIdsInCollections.has(section.id),
  );
  return matchedSections;
};
