// Copyright © 2022 Niphtio, Inc.
// All Rights Reserved.

import { useMemo, useState } from 'react';
import { Collection } from '~/common/gql';
import { filterCollectionByTitleSubstring } from './filterCollectionByTitleSubstring';

interface Props<T> {
  initialTerm?: string;
  collections: T[];
}

interface ReturnType<T> {
  searchTerm: string;
  setSearchTerm: (value: string) => void;
  result: T[];
}

export const useCollectionSearch = <T extends Pick<Collection, 'title'>>({
  initialTerm = '',
  collections,
}: Props<T>): ReturnType<T> => {
  const [searchTerm, setSearchTerm] = useState(initialTerm);

  const filteredCollections = useMemo(
    () =>
      filterCollectionByTitleSubstring({
        substring: searchTerm,
        collections: collections,
      }),
    [collections, searchTerm],
  );

  return {
    searchTerm,
    setSearchTerm,
    result: filteredCollections,
  };
};
