// Copyright © 2024 Niphtio, Inc.
// All Rights Reserved.

import throttle from 'lodash/throttle';
import { useCallback, useRef } from 'react';
import { useCollectionsQuery } from '~/common/gql/collection.generated';
import { useCollectionCreateQueryHelper } from '~/hooks/useCollectionCreateMutationHelper';
import { EnhancedCollection } from './EnhancedCollection';

export interface Props {
  setSearchTerm?: (term: string) => void;
  toggleSelected: (collection: EnhancedCollection) => void;
  throttleInterval: number; // ms
}

export const useCollectionSearchCreateCollection = ({
  setSearchTerm,
  toggleSelected,
  throttleInterval,
}: Props): ReturnType<typeof useCollectionCreateQueryHelper>[0] => {
  const [_createCollection] = useCollectionCreateQueryHelper();
  const collectionQuery = useCollectionsQuery();

  const __createCollection: typeof _createCollection = (
    variables,
    handlers,
  ) => {
    return _createCollection(variables, {
      ...handlers,
      onSuccess: (data) => {
        toggleSelected({ ...data.collectionCreate, selected: false }); /*
        select the collection i.e. 
        when selected = false, toggleSelected will set selected = true
        */
        handlers?.onSuccess(data);
        setSearchTerm(''); // reset search term
        collectionQuery.refetch();
      },
    });
  };

  const throttled = useRef(throttle(__createCollection, throttleInterval));

  const create = useCallback(
    (...props: Parameters<typeof _createCollection>) => {
      return throttled.current(...props);
    },
    [
      // dep array should be empty or only contain RefObjects
    ],
  );

  return create;
};
