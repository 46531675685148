// Copyright © 2021 Niphtio, Inc.
// All Rights Reserved.

import { useTodoItemDeleteMutation } from '~/common/gql/todoItem.generated';
import { ActionType, UsageContext } from '~/common/utilities/usage-event';
import { createMutationHelperHook } from './createMutationHelperHook';

export const useTodoItemDeleteMutationHelper = createMutationHelperHook(
  useTodoItemDeleteMutation,
  {
    errorMessage: 'There was an error deleting this todo item: ',
    log: {
      beforeMutation: (variables) => ({
        actionType: ActionType.DELETE_TODO,
        context: UsageContext.TODO,
        label: variables.input.id,
      }),
      afterMutation: (variables) => ({
        actionType: ActionType.TODO_DELETED,
        context: UsageContext.TODO,
        label: variables.input.id,
      }),
    },
  },
);
