// Copyright © 2022 Niphtio, Inc.
// All Rights Reserved.

import isEmpty from 'lodash/isEmpty';
import { Collection, Item, TodoItem } from '~/common/gql';
import {
  useCollectionCreateMutation,
  useCollectionsQuery,
  useRemoveItemFromCollectionMutation,
} from '~/common/gql/collection.generated';
import {
  useAddTagToItemMutation,
  useItemLazyQuery,
} from '~/common/gql/item.generated';
import { useTodoItemCreateMutationHelper } from '~/hooks/useTodoItemCreateMutationHelper';
import { useTodoItemDeleteMutationHelper } from '~/hooks/useTodoItemDeleteMutationHelper';
import { useTodoItemUpdateMutationHelper } from '~/hooks/useTodoItemUpdateMutationHelper';

interface Props {
  item: Pick<Item, 'id'>;
}

export const useItemTags = ({ item }: Props) => {
  const [createTodoItem] = useTodoItemCreateMutationHelper();
  const [updateTodoItem] = useTodoItemUpdateMutationHelper();
  const [deleteTodoItem] = useTodoItemDeleteMutationHelper();
  const [addTagToItemMutation] = useAddTagToItemMutation();
  const [createCollection] = useCollectionCreateMutation();
  const [removeItemFromCollectionMutation] =
    useRemoveItemFromCollectionMutation();

  const collectionsQuery = useCollectionsQuery();

  const [_refetchItem, itemQuery] = useItemLazyQuery({
    variables: {
      input: {
        itemId: item?.id,
      },
    },
    fetchPolicy: 'cache-first',
  });

  const refetchItem = () => {
    if (itemQuery.called) {
      itemQuery.refetch();
      return;
    }
    _refetchItem();
  };

  const createTodo = () =>
    createTodoItem({
      input: {
        itemId: item.id,
      },
    });

  const setTodoDone = (todoId: TodoItem['id'], markDone: boolean) =>
    updateTodoItem({
      input: {
        find: {
          id: todoId,
        },
        markDone,
      },
    }).then(() => refetchItem());

  const removeTodo = (todoId: TodoItem['id']) =>
    deleteTodoItem({
      input: {
        id: todoId,
      },
    }).then(() => refetchItem());

  const createTag = (title: string) =>
    createCollection({
      variables: {
        input: { title },
      },
    }).then((result) => {
      collectionsQuery.refetch();
      refetchItem();
      return result;
    });

  const addTag = (collectionId: number) =>
    addTagToItemMutation({
      variables: {
        input: {
          collectionId,
          find: {
            collectionId,
            id: item.id,
          },
        },
      },
    }).then(() => refetchItem());

  const removeTag = (collectionId: number) =>
    removeItemFromCollectionMutation({
      variables: {
        input: {
          collectionId,
          find: {
            collectionId,
            id: item.id,
          },
        },
      },
    }).then(() => refetchItem());

  const setItemTags = (
    item: Pick<Item, 'todo' | 'collection'>,
    todoSelected: boolean,
    tagsSelected: Collection['id'],
  ) => {
    if (!item) return;
    const currentTodos = item.todo;
    const currentTags = item.collection.map((it) => it.collectionId);
    const toAddTodo = todoSelected && isEmpty(currentTodos);
    const toRemoveTodos = todoSelected ? [] : currentTodos;
    const toRemoveTags = currentTags.filter((it) => !tagsSelected.includes(it));
    const toAddTags = tagsSelected.filter((it) => !currentTags.includes(it));

    const opAddTodo = !toAddTodo ? [] : [createTodo()];

    const opRemoveTodo = isEmpty(toRemoveTodos)
      ? []
      : toRemoveTodos.map((it) => removeTodo(it.id));

    const opAddTags = isEmpty(toAddTags)
      ? []
      : toAddTags.map((it) => addTag(it));

    const opRemoveTags = isEmpty(toRemoveTags)
      ? []
      : toRemoveTags.map((it) => removeTag(it));

    return Promise.all([
      ...opAddTodo,
      ...opRemoveTodo,
      ...opRemoveTags,
      ...opAddTags,
    ]).then(() => refetchItem());
  };

  return {
    // todos
    createTodo,
    setTodoDone,
    removeTodo,
    //tags
    createTag,
    addTag,
    removeTag,
    setItemTags,
  };
};
