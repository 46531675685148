// Copyright © 2024 Niphtio, Inc.
// All Rights Reserved.

import { MutableRefObject, useRef } from 'react';

/**
 * Like useRef, but keeps ref.current = value.
 * @param value
 * @returns
 */
export const useRefUpdater = <T>(value: T): MutableRefObject<T> => {
  const ref = useRef<T>(value);
  ref.current = value;
  return ref;
};
